// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bedankt-js": () => import("./../../../src/pages/bedankt.js" /* webpackChunkName: "component---src-pages-bedankt-js" */),
  "component---src-pages-bedrijfsfeesten-js": () => import("./../../../src/pages/bedrijfsfeesten.js" /* webpackChunkName: "component---src-pages-bedrijfsfeesten-js" */),
  "component---src-pages-contacteer-ons-js": () => import("./../../../src/pages/contacteer-ons.js" /* webpackChunkName: "component---src-pages-contacteer-ons-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-foodtruck-feesten-js": () => import("./../../../src/pages/foodtruck-feesten.js" /* webpackChunkName: "component---src-pages-foodtruck-feesten-js" */),
  "component---src-pages-gegevensbescherming-js": () => import("./../../../src/pages/gegevensbescherming.js" /* webpackChunkName: "component---src-pages-gegevensbescherming-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-media-js": () => import("./../../../src/pages/media.js" /* webpackChunkName: "component---src-pages-media-js" */),
  "component---src-pages-mobiel-pizzakraam-js": () => import("./../../../src/pages/mobiel-pizzakraam.js" /* webpackChunkName: "component---src-pages-mobiel-pizzakraam-js" */),
  "component---src-pages-recensies-js": () => import("./../../../src/pages/recensies.js" /* webpackChunkName: "component---src-pages-recensies-js" */),
  "component---src-pages-waarom-attorno-js": () => import("./../../../src/pages/waarom-attorno.js" /* webpackChunkName: "component---src-pages-waarom-attorno-js" */)
}

